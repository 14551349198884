

























































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';

import FlagshipLinkGoalsWizardLinkWealthViewModel
  from '@/vue-app/view-models/components/flagship/flagship-link-goals/flagship-link-goals-wizard-link-wealth-view-model';

@Component({
  name: 'FlagshipLinkGoalsWizardWealth',
  components: {
    StepChooseGoalsWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-wealth/StepChooseGoalsWealth.vue'),
    StepDistributeBalanceWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-wealth/StepDistributeBalanceWealth.vue'),
    StepSuccessWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-wealth/StepSuccessWealth.vue'),
    StepConfigureContributionsWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-wealth/StepConfigureContributionsWealth.vue'),
    StepSchedulePaymentDateWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-wealth/StepSchedulePaymentDateWealth.vue'),
  },
})
export default class FlagshipLinkGoalsWizardWealth extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('isActive', { type: Boolean, required: true })
  is_active!: boolean;

  @PropSync('associatedProductId', { type: String, required: true })
  associated_product_id!: string;

  flagship_link_wealth_model = Vue.observable(
    new FlagshipLinkGoalsWizardLinkWealthViewModel(),
  );

  @Watch('isOpen')
  initialize() {
    if (this.synced_is_open) {
      this.flagship_link_wealth_model.initialize(this.associated_product_id);
    }
  }

  closeModal() {
    this.synced_is_open = false;
  }

  endProcess() {
    this.flagship_link_wealth_model.endProcess();
    this.synced_is_open = false;
  }
}
