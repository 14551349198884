import TYPES from '@/types';

// Application
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  CustomerInvestorGoalBalanceQueryEntity,
} from '@/modules/flagship/customer-investor-goal-balance/domain/entities/customer-investor-goal-balance-query-entity';
import { InvestorGoalsDto } from '@/modules/flagship/investor-goal/link-sowos-wealth/domain/dtos/investor-goals-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class FlagshipLinkGoalsWizardLinkWealthViewModel {
  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals';

  private readonly customer_id = sessionStorage.getItem('user_id');

  is_loading = false;

  show_step_success_wealth = false;

  goals_selected = [];

  goals_amount_information = [];

  new_recurrent_amount = 0;

  investor_goals: InvestorGoalsDto = {
    total_active_goals_balance: '0.00',
    price_by_unit: '0.00',
    unassigned_balance: '0',
    recurrent_unassigned_amount: '',
    investor_goals: [],
  };

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  private customer_investor_goal_balance: CustomerInvestorGoalBalanceQueryEntity = {
    investment_product_id: '',
    reload: true,
  };

  private STEPS = [
    'StepChooseGoalsWealth',
    'StepDistributeBalanceWealth',
    'StepConfigureContributionsWealth',
    'StepSchedulePaymentDateWealth',
  ];

  current_step = 0;

  current_component = this.STEPS[this.current_step];

  has_active_goals = false;

  balance = 0;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  menu_steps = this.translate('wealth.steps_link_wealth_with_balance');

  initialize = async (associated_product_id: string) => {
    if (associated_product_id) {
      this.customer_investor_goal_balance.investment_product_id = associated_product_id;
      this.search_by_customer_dto.associated_product_id = associated_product_id;
      await this.loadActiveGoals();
      if (this.has_active_goals) {
        await this.loadCustomerInvestorGoalBalance();
      } else {
        await this.loadAllianzBalance();
      }
      this.setCorrectMenuSteps();
    }
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      const active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      if (active_goals.length) {
        this.has_active_goals = true;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('wealth.errors.load_active_goals'));
    }
  }

  loadCustomerInvestorGoalBalance = async () => {
    try {
      // eslint-disable-next-line max-len
      const { unassigned_balance } = await this.get_customer_investor_goal_balance_query.execute(this.customer_investor_goal_balance);
      this.balance = unassigned_balance;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('wealth.errors.load_customer_investor_goal_balance'));
      }
    }
  }

  loadAllianzBalance = async () => {
    try {
      const allianz_account_report_list = await this.search_allianz_account_report_query.execute(
        this.customer_id,
      );
      if (allianz_account_report_list.length) {
        allianz_account_report_list.forEach((position) => {
          if (position.found_code === 'SWSRFP') {
            this.balance = position.final_balance_mxn || 0;
          }
        });
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('wealth.errors.get_allianz_balance'));
    }
  }

  setCorrectMenuSteps = () => {
    if (this.balance > 0) {
      this.STEPS = [
        'StepChooseGoalsWealth',
        'StepDistributeBalanceWealth',
        'StepConfigureContributionsWealth',
        'StepSchedulePaymentDateWealth',
      ];
      this.menu_steps = this.translate('wealth.steps_link_wealth_with_balance');
    } else {
      this.STEPS = [
        'StepChooseGoalsWealth',
        'StepConfigureContributionsWealth',
        'StepSchedulePaymentDateWealth',
      ];
      this.menu_steps = this.translate('wealth.steps_link_wealth_without_balance');
    }
  }

  nextStep = () => {
    if (this.current_step < this.STEPS.length) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess= () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
    this.show_step_success_wealth = true;
  }

  changeLoadingStep = (loading: boolean) => {
    this.is_loading = loading;
  }

  changeGoalsSelected = (goals: []) => {
    this.goals_selected = goals;
  }

  changeInvestorGoals = (investor_goals: InvestorGoalsDto) => {
    this.investor_goals = { ...investor_goals };
  }

  changeGoalsAmountInformation = (goals_information: []) => {
    this.goals_amount_information = goals_information;
  }

  changeRecurrentAmount = (new_recurrent_amount: number) => {
    this.new_recurrent_amount = new_recurrent_amount;
  }

  changeRecurrentUnassignedAmount = (recurrent_unassigned_amount: string) => {
    this.investor_goals.recurrent_unassigned_amount = recurrent_unassigned_amount;
  }

  createInvestorGoalsDto = () => {
    if (this.balance <= 0) {
      this.investor_goals.investor_goals.length = 0;
      this.goals_selected.forEach((investor_goal_id: string) => {
        this.investor_goals.investor_goals.push({
          investor_goal_id,
          active_goal_amount: '0',
          recurrent_assigned_amount: '0',
        });
      });
    }
  }
}
